import { PostCard } from '../PostCard'

import styles from './postswrapper.module.scss'

const PostsWrapper = ({ posts, title, imagePriority }) => {
  return (
    <>
    <section className={styles.postsWrapper}>
      {title && (
        <h2><span>{title}</span></h2>
      )}

      <div className={styles.postsList}>
        {posts.map((post, index) => (
          <PostCard
            key={post.uid}
            excerpt={post.excerpt}
            imgURL={post.image.url}
            imgAltText={post.image.alt}
            link={post.url}
            formattedDate={post.formattedDate}
            title={post.title}
            tags={post?.tags}
            datatime={post.publicationDate}
            priorityLoad={index < imagePriority}
          />
        ))}
      </div>
    </section>
    </>
  )
}

export { PostsWrapper }