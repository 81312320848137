import Image from "next/image"
import Link from "next/link"
import { AiOutlineCalendar } from 'react-icons/ai'

import styles from './postcard.module.scss'

const PostCard = ({ showExcerpt = true, link, imgURL, imgAltText, tags, formattedDate, datatime, title, excerpt, priorityLoad }) => {
  return (
    <article className={styles.postCard}>
      <figure>
        <Link href={link} aria-label={`Navegar para ${title}`}>
          <Image src={imgURL} alt={imgAltText || title} width={360} height={202} priority={priorityLoad} />
        </Link>

        <div className={styles.postCategories}>
          {tags?.map(tag => (
            <Link
              key={tag}
              href={`/tag/${tag.toLowerCase()}`}
              aria-label={`Navegar para categoria ${tag}`}
              className={styles.postTag}>{tag}</Link>
          ))}
        </div>
      </figure>

      <div className={styles.content}>
        <time dateTime={datatime}>
          <AiOutlineCalendar />
          {formattedDate}
        </time>

        <Link href={link} className="linkHover">
          <h2>{title}</h2>
        </Link>

        {showExcerpt && (
          <p>{excerpt}</p>
        )}
      </div>
    </article>
  )
}

export { PostCard }