import { useEffect, useState } from "react";

import { createClient } from "../../prismicio";
import { CategoriesSection } from "../components/CategoriesSection";

import { Layout } from "../components/Layout";
import { PostsWrapper } from "../components/PostsWrapper";
import { SEO } from "../components/SEO";
import { ReferralBanner } from "../components/AdsTerra";

export default function Home({ posts }) {
  const [posts0To2, setPosts0To2] = useState([]);
  const [posts3To5, setPosts3To5] = useState([]);

  useEffect(() => {
    if (posts.length > 6) {
      setPosts0To2(posts.slice(0, 6));
      setPosts3To5(posts.slice(6));
    } else {
      setPosts0To2(posts);
    }
  }, [posts]);

  return (
    <>
      <SEO />

      <Layout>
        {!posts0To2.length && !posts3To5.length && (
          <p>Que pena, parece que ainda não temos nenhuma publicação!</p>
        )}

        {!!posts0To2.length && (
          <PostsWrapper title="Recentes" posts={posts0To2} imagePriority={6} />
        )}

        <ReferralBanner />

        <CategoriesSection title="Navegue por categorias" />

        {!!posts3To5.length && (
          <PostsWrapper
            title="Veja também"
            posts={posts3To5}
            imagePriority={0}
          />
        )}
      </Layout>
    </>
  );
}

export async function getStaticProps() {
  const client = createClient();

  const { results } = await client.getByType("article", {
    pageSize: 18,
    orderings: {
      field: "document.first_publication_date",
      direction: "desc",
    },
  });

  const posts = results.map((post) => {
    return {
      formattedDate: new Intl.DateTimeFormat("pt-BR", {
        timeZone: "UTC",
      }).format(new Date(post.first_publication_date)),
      publicationDate: post.first_publication_date,
      tags: post?.tags,
      uid: post?.uid,
      url: post?.url,
      title: post.data?.title[0].text,
      excerpt: post.data?.excerpt[0].text,
      image: post.data?.post_image,
    };
  });

  return {
    props: {
      posts,
    },
  };
}
