import Link from "next/link"
import { TbCode } from "react-icons/tb"
import { ImNewspaper } from "react-icons/im"
import { AiOutlineApi } from "react-icons/ai"
import { MdOutlineTravelExplore } from "react-icons/md"

import styles from './categories.section.module.scss'

const CategoriesSection = ({ title }) => {
  return (
    <section className={styles.categoriesSection}>
      <h3>{title}</h3>

      <ul>
        <li>
          <h4>
            <Link href="/tag/explore" className="linkHover">
              <MdOutlineTravelExplore />
              <span>Explore</span>
            </Link>
          </h4>
        </li>

        <li>
          <h4>
            <Link href="/tag/news" className="linkHover">
              <ImNewspaper />
              <span>News</span>
            </Link>
          </h4>
        </li>

        <li>
          <h4>
            <Link href="/tag/frontend" className="linkHover">
              <TbCode />
              <span>Frontend</span>
            </Link>
          </h4>
        </li>

        <li>
          <h4>
            <Link href="/tag/backend" className="linkHover">
              <AiOutlineApi />
              <span>Backend</span>
            </Link>
          </h4>
        </li>
      </ul>
    </section>
  )
}

export { CategoriesSection }